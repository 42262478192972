import { VisuallyHidden } from "@99tech/ui";
import { Box, Stack, Typography } from "@mui/material";
import React, { MouseEvent, useCallback, useContext } from "react";
import {
  BgSection,
  BtnDownloadAppstore,
  BtnDownloadGoogleplay,
  Chars,
  LoginButtonImage,
  Logo,
  LogoHero,
  Payments,
  Providers,
  RegisterButtonImage,
  Text1,
  Text2,
  Text3,
} from "src/assets";
import LineSupportButton from "src/main/components/LineSupportButton";
import { AppContext } from "src/main/constants";
import useGtmTracker, { EvtAction } from "src/main/hooks/useGtmTracker";
import { createStyles, isMobile } from "src/main/utils";

interface LandingPageProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  const { sendEvent } = useGtmTracker();
  const { urls } = useContext(AppContext);
  const { loginUrl, registerUrl } = urls ?? {};

  const handleClick = useCallback(
    (type: "login" | "register") => (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (type === "login") {
        sendEvent(EvtAction.ClickLogin);
        window.open(loginUrl, "_blank", isMobile() ? "width=full,height=full" : "");
      } else {
        sendEvent(EvtAction.ClickRegister);
        window.open(registerUrl, "_blank", isMobile() ? "width=full,height=full" : "");
      }
    },
    [loginUrl, registerUrl, sendEvent],
  );

  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">Go989</VisuallyHidden>
      <img
        src={Text1}
        alt="text-1"
        width={226}
      />
      <img
        alt="logo"
        src={LogoHero}
        width={350}
      />
      <img
        src={Text2}
        alt="text-2"
        width={250}
        style={{
          marginTop: "-34px",
        }}
      />

      <Box sx={styles.buttons}>
        <a
          href={loginUrl}
          onClick={handleClick("login")}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={LoginButtonImage}
            alt="login"
            height={46}
          />
        </a>
        <a
          href={registerUrl}
          onClick={handleClick("register")}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={RegisterButtonImage}
            alt="register"
            height={48}
          />
        </a>
      </Box>

      <img
        src={Chars}
        alt="chars"
        style={{
          marginTop: "-20px",
        }}
        width={338}
      />

      <img
        src={Text3}
        alt="text-3"
        width={320}
        style={{
          marginTop: "-4px",
        }}
      />

      <Box
        sx={{
          background: `linear-gradient(180deg, #2E005C 0%, #120125 100%)`,
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          textAlign={"center"}
          paddingX={"16px"}
          spacing="12px"
          sx={{
            backgroundImage: `url(${BgSection})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "right",
            backgroundPositionY: "80%",
          }}
        >
          <img
            alt="logo"
            src={Logo}
            width={180}
          />

          <Typography sx={styles.text}>
            ✨ ยินดีต้อนรับสู่ GO989.GG สวรรค์สำหรับนักปั่นเกมสล็อตที่น่าสนุกสุดปึ้งค่ะคุณน้าาาา! ✨ <br />
            คุณกำลังมองหาความตื่นเต้นและชนะเยอะ ๆ อยู่ใช่ไหม? อย่ามองไปไกล มาที่ GO989.GG เราคือ ที่หมายอันทรงพลัง
            สำหรับประสบการณ์การเล่นสล็อตที่ไม่เหมือนใคร GO989 ดาวน์โหลดแอปได้แล้วที่
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-evenly"
            width="100%"
          >
            <img
              alt="download app store"
              src={BtnDownloadAppstore}
              width={106}
            />
            <img
              alt="download google play"
              src={BtnDownloadGoogleplay}
              width={106}
            />
          </Stack>

          <Typography sx={styles.text}>
            GO989.GG มีความหลากหลายของเกมสล็อต เต็มไปด้วยธีมที่น่าสนใจ และคุณสมบัติโบนัสที่น่าตื่นเต้น
            ตั้งแต่เกมโปรดแบบคลาสสิคจนถึงการเปิดตัวใหม่ GO989 มีสิ่งที่เหมาะกับผู้เล่นทุกคน GO989 แจกรางวัลแจ็คพอตใหญ่
            คุณจะเป็นผู้ชนะคนต่อไปของเราหรือไม่? GO989.com ปลอดภัยและมั่นคง เล่นอย่างมั่นใจ
            เรารู้ว่าความรู้สึกปลอดภัยและความมั่นคงของคุณคือ สิ่งสำคัญสูงสุดของเรา GO989
            ได้รับใบอนุญาตและมีการรักษามาตรฐานการรักษาความปลอดภัยที่ทันสมัย เพื่อให้ได้รับการเล่นเกม
            ที่ยุติธรรมและปลอดภัย ที่ GO989 คุณจะเพลิดเพลินกับโบนัสต้อนรับ รวมถึงฟรีสปินและโปรโมชั่นที่น่าตื่นเต้น
            ที่ช่วยเพิ่มความสนุกและการชนะของคุณ GO989 เข้าถึงได้ทุกที่ ทุกเวลา สัมผัสความตื่นเต้นของ GO989.com
            ทุกที่คุณไป! เว็บไซต์ GO989.com ของเราได้รับการปรับปรุงให้เหมาะสมสำหรับอุปกรณ์เคลื่อนที่ทุกชนิด
            ช่วยให้คุณสามารถเพลิดเพลินกับ เกมสล็อตที่ชื่นชอบได้ทุกที่ ทุกเวลา ที่ GO989.GG
          </Typography>

          <img
            alt="payments"
            src={Payments}
            width={190}
          />

          <Typography sx={styles.text}>
            ทีมงานบริการลูกค้าที่เป็นมิตรของเราพร้อมให้บริการตลอด 24 ชั่วโมง ติดต่อเราที่ GO989.GG
            พร้อมที่จะเริ่มการผจญภัยในการเล่นสล็อตแล้วใช่ไหม? สมัครสมาชิกกับ GO989.GG วันนี้และเริ่มความสนุกได้เลย!
            แล้วมาพบกับค่ายเกมชั้นนำมากมาย
          </Typography>
        </Stack>

        <Box
          sx={{
            marginY: "12px",
            background: "#E9A731",
            height: "1px",
          }}
        />

        <Box
          sx={{
            textAlign: "center",
            paddingBottom: "10px",
          }}
        >
          <img
            alt="providers"
            src={Providers}
            width={300}
          />
        </Box>
      </Box>
      <LineSupportButton />
    </Box>
  );
};

const styles = createStyles({
  root: {
    pt: "28px",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
    color: "white",
  },
  images: {
    zIndex: 10,
    position: "relative",
    width: "360px",
    height: "380px",
    img: {
      position: "absolute",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "18px",
    padding: "0px 20px",
    gap: "8px",

    a: {
      // width: "50%",
      cursor: "pointer",
    },
  },
  footer: {
    zIndex: 0,
    position: "fixed",
    bottom: "0",
    width: "100vw",
    height: "200px",
    flexShrink: 0,
  },
  hightlight: {
    background: "linear-gradient(180deg, #D37E00 1%, #FEDE79 35%, #D37E00 68.5%, #FDD65A 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  title: {
    fontSize: "40px",
    fontWeight: 800,
    textAlign: "center",
    lineHeight: "1.1",
  },
  title2: {
    fontSize: "22px",
    fontWeight: 600,
    textAlign: "center",
    lineHeight: "1.3",
  },
  text: {
    fontSize: "8px",
    fontWeight: 700,
    lineHeight: "1.3",
  },
});

export default LandingPage;
