import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const DEFAULT_URL = "";

export const LOGIN_URL = "https://a.ntrk2.com/bb7fb09e-46a6-490d-961d-50217272d282";
export const REGISTER_URL = "https://a.ntrk2.com/b4f951c1-f3c3-4902-9d8c-243eb7c78dc9";

export const LOGIN_URL_MAP = {
  "www-go989play.com": "https://a.ntrk2.com/110d72fd-95d9-4a9b-bd52-5b8e83475fa3",
  "go989a.com": "https://a.ntrk2.com/110d72fd-95d9-4a9b-bd52-5b8e83475fa3",
};

export const REGISTER_URL_MAP = {
  "www-go989play.com": "https://a.ntrk2.com/8b8779a5-fef6-44de-b08b-c13f03a19bda",
  "go989a.com": "https://a.ntrk2.com/8b8779a5-fef6-44de-b08b-c13f03a19bda",
};

export const LineSupportLink = "https://go989thai.com/cs";

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export * from "./codes";
export * from "./context";
